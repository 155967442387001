<template>
    <div id="transactions-list">
        <div v-if="this.transactions">
          <b-table stripe :items="this.transactions" :fields="depositFields"></b-table>
        </div>
    </div>
</template>

<script>
import {BCard,BRow, BCol, BTable,} from 'bootstrap-vue'
export default{
  data () {
    return {
      depositFields : ['description', 'user_email', 'user_name', 'value', 'status', 'created_at'],
      transactions:false,
    }
  },
  computed: {},
  methods: {
    getDeposits(){
      this.$httpPlatform.get('https://api.bingolar.tv/api/platform-operations/getLastDeposits')
      .then(res => {
          this.transactions = res.data;
          console.log(res.data)
      })
    }
    
  },
  components: {
    BCard,
    BTable,
    BRow, BCol,
  },
  created() {
    this.getDeposits();
  }
}
</script>


